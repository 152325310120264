/** @format */

import React, { lazy, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import Home from "./Home";
const Refinance = lazy(() => import("./Refinance/Form"));
const RefinanceHomeLoan = lazy(() => import("./RefinanceHomeLoan/Form"));
const LifeInsurance = lazy(() => import("./FormLifeInsurance/Form"));
const LifeInsuranceSL = lazy(() => import("./FormLifeInsuranceSl/Form"));
const SolarForm = lazy(() => import("./FormSolar/Form"));
// const RefinanceNow = lazy(() => import("./FormRefinanceNow/Form"));
const HomeLoan = lazy(() => import("./FormHomeLoan/Form"));
const SolarFormFb = lazy(() => import("./FormSolarFb/Form"));
const FormProperty = lazy(() => import("./FormProperty/Form"));
const FormPropertyFB = lazy(() => import("./FormPropertyFB/Form"));
const FormPropertyFB2FA = lazy(() => import("./FormPropertyFB2FA/Form"));
const RefinanceFB = lazy(() => import("./RefinanceFb/Form"));
const DebtFB = lazy(() => import("./DebtFb/Form"));

const ElectricityAndGas = lazy(() => import("./ElectricityAndGas/Form"));
const Superannuation = lazy(() => import("./Superannuation/Form"));

// const RefinanceJfb = lazy(() => import("./RefinanceJFb/Form"));
const RefinanceNow = lazy(() => import("./RefinanceNow/Form"));
const ShopadocketRefinance = lazy(() => import("./ShopadocketRefinance/Form"));
// const Refinance2 = lazy(() => import("./Refinance2"));
const HealthInsurance = lazy(() => import("./HealthInsurance/Form"));

const HomeLoanFb = lazy(() => import("./HomeLoanFb/index"));
const HomeLoanFbSuccess = lazy(() => import("./HomeLoanFb/Form/Success"));

const BookAppointment = lazy(() => import("./BookApointment/index"));
const BookAppointmentRBA = lazy(() => import("./BookApointmentRBA/index"));
const SuperannuationFB = lazy(() => import('./SuperannuationFB'));
const SuperannuationFBSuccess = lazy(() => import('./SuperannuationFB/Form/Success'));
const SuperannuationFBTest = lazy(() => import('./SuperannuationFBTest'));
const SuperannuationFBTestSuccess = lazy(() => import('./SuperannuationFBTest/Form/Success'));
const CompareSuperannuationFB = lazy(() => import('./CompareSuperannuationFB'));
const CompareSuperannuationFBSuccess = lazy(() => import('./CompareSuperannuationFB/Form/Success'));

const FormLifeInsuranceNew = lazy(() => import('./FormLifeInsuranceNew'));
const FormLifeInsuranceNewSuccess = lazy(() => import('./FormLifeInsuranceNew/Form/Success'));
// const RefinanceFormLP2 = lazy(() => import('./RefinanceFormLP2'));
// const RefinanceFormLP2Success = lazy(() => import('./RefinanceFormLP2/Form/Success'));
const RefinanceFormLP3 = lazy(() => import('./RefinanceFormLP3'));
const RefinanceFormLP3Success = lazy(() => import('./RefinanceFormLP3/Form/Success'));
const RefinanceJoust = lazy(() => import('./RefinanceJoust'));

const Router = () => {
  const { listen } = useHistory();
  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return;
      window.gtag("config", "GTM-TC9KTLT", {
        page_path: location.pathname,
      });
    });
    return unlisten;
  }, [listen]);

  return (
    <Switch>
      <Route path="/refinance">
        <Refinance />
      </Route>
      {/* <Route path="/refinance-2">
        <Refinance2 />
      </Route> */}
      <Route path="/refinance-home-loan">
        <RefinanceHomeLoan />
      </Route>
      <Route path="/refinance-fb">
        <RefinanceFB />
      </Route>
      <Route path="/life-insurance">
        <LifeInsurance />
      </Route>
      <Route path="/life-insurance-sl">
        <LifeInsuranceSL />
      </Route>
      <Route path="/solar">
        <SolarForm />
      </Route>
      <Route path="/refinance-now">
        <RefinanceNow />
      </Route>
      <Route path="/shopadocket-refinance">
        <ShopadocketRefinance />
      </Route>
      <Route path="/home-loan">
        <HomeLoan />
      </Route>
      <Route path="/solar-fb">
        <SolarFormFb />
      </Route>
      <Route path="/property">
        <FormProperty />
      </Route>
      <Route path="/property-fb">
        <FormPropertyFB />
      </Route>
      <Route path="/property-fb-2fa">
        <FormPropertyFB2FA />
      </Route>
      <Route path="/debt-fb">
        <DebtFB />
      </Route>
      <Route path="/electricity-and-gas">
        <ElectricityAndGas />
      </Route>
      <Route path="/superannuation">
        <Superannuation />
      </Route>
      {/* <Route path="/refinance-jfb">
        <RefinanceJfb />
      </Route> */}
      <Route path="/health-insurance">
        <HealthInsurance />
      </Route>
      <Route path="/home-loan-fb/step-success">
        <HomeLoanFbSuccess />
      </Route>
      <Route path="/home-loan-fb">
        <HomeLoanFb />
      </Route>
      <Route path="/book-appointment">
        <BookAppointment />
      </Route>
      <Route path="/book-appointment-rba">
        <BookAppointmentRBA />
      </Route>
      <Route path="/superannuation-fb/step-success">
        <SuperannuationFBSuccess/>
      </Route>
      <Route path="/superannuation-fb">
        <SuperannuationFB />
      </Route>
      <Route path="/compare-superannuation-fb/step-success">
        <CompareSuperannuationFBSuccess/>
      </Route>
      <Route path="/compare-superannuation-fb">
        <CompareSuperannuationFB />
      </Route>
      <Route path="/superannuation-fb-test/step-success">
        <SuperannuationFBTestSuccess/>
      </Route>
      <Route path="/superannuation-fb-test">
        <SuperannuationFBTest />
      </Route>
      <Route path="/life-insurance-lp2/step-success">
        <FormLifeInsuranceNewSuccess/>
      </Route>
      <Route path="/life-insurance-lp2">
        <FormLifeInsuranceNew />
      </Route>
      {/* <Route path="/refinance-lp2/step-success">
        <RefinanceFormLP2Success/>
      </Route>
      <Route path="/refinance-lp2">
        <RefinanceFormLP2 />
      </Route> */}
      <Route path="/refinance-lp3/step-success">
        <RefinanceFormLP3Success/>
      </Route>
      <Route path="/refinance-lp3">
        <RefinanceFormLP3 />
      </Route>
      <Route path="/refinance-joust">
        <RefinanceJoust />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
    </Switch>
  );
};

export default Router;
